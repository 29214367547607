import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import StimulusReflex from 'stimulus_reflex';
import consumer from '../channels/consumer';
import Sortable from 'stimulus-sortable';
import Popover from 'stimulus-popover';
import TextareaAutogrow from 'stimulus-textarea-autogrow';
import { install } from '@github/hotkey';

const application = Application.start();
const context = require.context('', true, /_controller\.js$/);
application.load(definitionsFromContext(context));
application.consumer = consumer;
StimulusReflex.initialize(application, { isolate: true, debug: false });
application.register('sortable', Sortable);
application.register('popover', Popover);
application.register('textarea-autogrow', TextareaAutogrow);

document.addEventListener('turbo:load', function (e) {
// Install all the hotkeys on the page
  for (const el of document.querySelectorAll('[data-hotkey]')) {
    install(el);
  }
});
