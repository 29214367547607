import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['command']

  close() {
    this.element.innerHTML = '';
  }

  submit(event) {
    const command = this.commandTarget.value;

    switch (command) {
      case 'ca':
      case 'cal':
      case 'calendar':
        event.preventDefault();
        this.element.innerHTML = '';
        window.location = '/tracker/home/calendar';
        break;
      case 'my':
        event.preventDefault();
        this.element.innerHTML = '';
        window.location = '/tracker/home/my_tasks';
        break;
      case 'home':
        event.preventDefault();
        this.element.innerHTML = '';
        window.location = '/tracker/home';
        break;
      case 'projects':
        event.preventDefault();
        this.element.innerHTML = '';
        window.location = '/tracker/projects';
        break;
      case 'pm':
      case 'pomodoros':
        event.preventDefault();
        this.element.innerHTML = '';
        window.location = '/tracker/home/pomodoros';
        break;
    }

    if (command.startsWith('p:')) {
      event.preventDefault();
      this.element.innerHTML = '';
      const cmd = command.split(':');
      window.location = '/tracker/projects/' + cmd[1] + '/todos';
    }
  }
}
