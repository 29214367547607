import ApplicationController from './application_controller.js';

export default class extends ApplicationController {
  beforeReflex() {
    document.getElementById('spinner').classList.remove('hidden');
  }

  afterReflex() {
    document.getElementById('spinner').classList.add('hidden');
  }

  close(event) {
    this.stimulate('Search#focusout');
    event.target.blur();
  }

  show(event) {
    document.getElementById("search-field").focus();
  }
}
