import { Controller as n } from '@hotwired/stimulus';
import s from 'sortablejs';
import { patch as o } from '@rails/request.js';
class r extends n {
  initialize() {
    this.onUpdate = this.onUpdate.bind(this);
    this.onAdd = this.onAdd.bind(this);
  }

  connect() {
    this.sortable = new s(this.element, {
      ...this.defaultOptions,
      ...this.options
    });
  }

  disconnect() {
    this.sortable.destroy(), this.sortable = void 0;
  }

  async onAdd({ item: t, newIndex: a, to: b }) {
  // async onAdd(env) {
  //   console.log('>>> drop', env);
  //   console.log('>>> drop', env.to.dataset);
    console.log('>>> drop', t, a, t.dataset.sortableAddUrl);
    console.log('item', t);
    if (!t.dataset.sortableAddUrl) { return; }
    const i = this.resourceNameValue ? `${this.resourceNameValue}[${this.paramNameValue}]` : this.paramNameValue; const e = new FormData();
    e.append('status', b.dataset.status);
    e.append(i, a + 1);
    const bb = await o(t.dataset.sortableAddUrl, { body: e, responseKind: this.responseKindValue });
    const blob = await bb.response.blob();
    blob.text().then((myBlob) => {
      // const objectURL = URL.createObjectURL(myBlob);
      // myImage.src = objectURL;
      document.getElementById(t.dataset.recordId).innerHTML = myBlob;
    });

    return e, bb;
  }

  async onUpdate({ item: t, newIndex: a }) {
    console.log('>>> update');
    if (!t.dataset.sortableUpdateUrl) { return; }
    const i = this.resourceNameValue ? `${this.resourceNameValue}[${this.paramNameValue}]` : this.paramNameValue; const e = new FormData();
    // const bb = await o(t.dataset.sortableUpdateUrl, { body: e, responseKind: this.responseKindValue });

    e.append(i, a + 1);
    const bb = await o(t.dataset.sortableUpdateUrl, { body: e, responseKind: this.responseKindValue });
    const blob = await bb.response.blob();
    blob.text().then((myBlob) => {
      console.log('myBlob', myBlob);
      // const objectURL = URL.createObjectURL(myBlob);
      // myImage.src = objectURL;
      document.getElementById(t.dataset.recordId).innerHTML = myBlob;
    });

    return e, bb;

    // return e.append(i, a + 1), await o(t.dataset.sortableUpdateUrl, { body: e, responseKind: this.responseKindValue });
  }

  get options() {
    return {
      animation: this.animationValue || this.defaultOptions.animation || 150,
      handle: this.handleValue || this.defaultOptions.handle || void 0,
      onUpdate: this.onUpdate,
      onAdd: this.onAdd
    };
  }

  get defaultOptions() {
    return {
      group: 'shared'

    };
  }
}
r.values = {
  resourceName: String,
  paramName: {
    type: String,
    default: 'position'
  },
  responseKind: {
    type: String,
    default: 'html'
  },
  animation: Number,
  handle: String
};
export {
  r as default
};
