import ApplicationController from './application_controller.js';

export default class extends ApplicationController {
  static targets = ['estimate']

  m15(event) {
    event.preventDefault();
    this.estimateTarget.value = 15;
  }
  m30(event) {
    event.preventDefault();
    this.estimateTarget.value = 30;
  }

  h1(event) {
    event.preventDefault();
    this.estimateTarget.value = 60;
  }

  h2(event) {
    event.preventDefault();
    this.estimateTarget.value = 120;
  }

  h4(event) {
    event.preventDefault();
    this.estimateTarget.value = 240;
  }
}
