import ApplicationController from './application_controller';
export default class extends ApplicationController {
  initialize() {
    this.open = true;
  }

  toggle (event) {
    if (this.open === true) {
      document.getElementById('sidebar').hidden = this.open;
      this.open = false;
    } else {
      document.getElementById('sidebar').hidden = this.open;
      this.open = true;
    }
  }
}
