import ApplicationController from './application_controller.js';
import moment from 'moment';

export default class extends ApplicationController {
  static targets = ['dueOn']

  d0(event) {
    event.preventDefault();
    this.dueOnTarget.value = moment().add(0, 'days').format('yyyy-MM-DD');
  }

  d1(event) {
    event.preventDefault();
    this.dueOnTarget.value = moment().add(1, 'days').format('yyyy-MM-DD');
  }

  d2(event) {
    event.preventDefault();
    this.dueOnTarget.value = moment().add(2, 'days').format('yyyy-MM-DD');
  }

  w1(event) {
    event.preventDefault();
    this.dueOnTarget.value = moment().add(1, 'weeks').format('yyyy-MM-DD');
  }

  m1(event) {
    event.preventDefault();
    this.dueOnTarget.value = moment().add(1, 'months').format('yyyy-MM-DD');
  }

  yesterday(event) {
    event.preventDefault();
    this.dueOnTarget.value = moment().subtract(1, 'day').format('yyyy-MM-DD');
  }

  today(event) {
    event.preventDefault();
    this.dueOnTarget.value = moment().format('yyyy-MM-DD');
  }

  tomorrow(event) {
    event.preventDefault();
    this.dueOnTarget.value = moment().add(1, 'day').format('yyyy-MM-DD');
  }

  setWeekday(day) {
    if (moment().day() > day) {
      this.dueOnTarget.value = moment().day(7 + day).format('yyyy-MM-DD');
    } else {
      this.dueOnTarget.value = moment().day(day).format('yyyy-MM-DD');
    }
  }

  mon(event) {
    event.preventDefault();
    this.setWeekday(1)
  }

  tue(event) {
    event.preventDefault();
    this.setWeekday(2)
  }

  wed(event) {
    event.preventDefault();
    this.setWeekday(3)
  }

  thu(event) {
    event.preventDefault();
    this.setWeekday(4)
  }

  fri(event) {
    event.preventDefault();
    this.setWeekday(5)
  }

  sat(event) {
    event.preventDefault();
    this.setWeekday(6)
  }

  sun(event) {
    event.preventDefault();
    this.setWeekday(7)
  }
}
