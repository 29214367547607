import ApplicationController from './application_controller.js';

export default class extends ApplicationController {
  static targets = ['items'];

  toggle(event) {
    if (this.itemsTarget.classList.contains('hidden')) {
      this.itemsTarget.classList.remove('hidden');
    } else {
      this.itemsTarget.classList.add('hidden');
    }
  }
}
