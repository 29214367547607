import ApplicationController from './application_controller.js';

export default class extends ApplicationController {
  static targets = ['priority']

  low(event) {
    event.preventDefault();
    this.priorityTarget.value = 'low';
  }

  normal(event) {
    event.preventDefault();
    this.priorityTarget.value = 'normal';
  }

  high(event) {
    event.preventDefault();
    this.priorityTarget.value = 'high';
  }
}
