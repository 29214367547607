import ApplicationController from './application_controller';
export default class extends ApplicationController {
  connect () {
    super.connect();
  }

  submit (event) {
    this.element.submit();
  }
}
